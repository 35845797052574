<template>
  <div id="app">
    <Scene
      :infoList="infoList"
      :imgList="imgList"
      :frameDescribe="frameDescribe"
      @toggleScreen="toggleScreen"
    />
  </div>
</template> 

<script>
import Scene from "./components/Scene.vue";

export default {
  name: "App",
  components: {
    Scene,
  },
  data: () => {
    return {
      imgList: [
        {
          step: 1,
          isMain: true,
          name: "1-m",
          hasSub: true,
          cameraRot: { main: { x: 0, y: -1.4 }, sub: { x: 0, y: -1.7 } },
          clickAreasMain: [
            {
              rot: "0 10 0",
              width: 1.5,
              height: 1.5,
              infoLoc: "bottom",
            },
          ],
          subAreas: [
            {
              rot: "0 -10 -5",
              width: 1.5,
              height: 1.5,
              infoLoc: "top",
            },
          ],
        },
        {
          step: 2,
          isMain: true,
          name: "2-m",
          hasSub: true,
          cameraRot: { main: { x: 0.3, y: 4.2 }, sub: { x: 0, y: 4.7 } },
          clickAreasMain: [
            {
              rot: "0 -30 20",
              width: 1.2,
              height: 1.2,
              infoLoc: "bottom",
            },
          ],
          subAreas: [
            {
              rot: "0 0 0",
              width: 1.4,
              height: 1.4,
              infoLoc: "bottom",
            },
          ],
        },
        {
          step: 3,
          isMain: true,
          name: "3-m",
          hasSub: true,
          cameraRot: { main: { x: -0.2, y: 4.6 }, sub: { x: 0.2, y: 4.78 } },
          clickAreasMain: [
            {
              rot: "0 -5 -20",
              width: 1.8,
              height: 1.5,
              infoLoc: "top",
            },
          ],
          subAreas: [
            {
              rot: "0 5 0",
              width: 1.5,
              height: 1.2,
              infoLoc: "top",
            },
          ],
        },
        {
          step: 4,
          isMain: true,
          name: "4-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0, y: 4.47 } },
          clickAreasMain: [
            {
              rot: "0 -15 -5",
              width: 2,
              height: 1.4,
              infoLoc: "top",
            },
          ],
        },
        {
          step: 5,
          isMain: true,
          name: "5-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: -0.2, y: 5.16 } },
          clickAreasMain: [
            {
              width: 1.5,
              height: 1.5,
              rot: "0 25 0",
              infoLoc: "bottom",
            },
          ],
        },
        {
          step: 6,
          isMain: true,
          name: "6-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: -0.2, y: 1.57 } },
          clickAreasMain: [
            {
              rot: "0 180 -27",
              infoLoc: "top",
              width: 1.2,
              height: 1,
            },
          ],
        },
        {
          step: 7,
          isMain: true,
          name: "7-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.1, y: 1.52 } },
          clickAreasMain: [
            {
              rot: "3 180 6",
              infoLoc: "top",
              width: 1.5,
              height: 1.4,
            },
          ],
        },
        {
          step: 8,
          isMain: true,
          name: "8-m",
          hasSub: true,
          cameraRot: { main: { x: -0.3, y: 4.3 }, sub: { x: -0.3, y: 4.7 } },
          clickAreasMain: [
            {
              rot: "0 -25 -30",
              infoLoc: "top",
              width: 1.5,
              height: 1.5,
            },
          ],
          subAreas: [
            {
              rot: "0 -3 -15",
              infoLoc: "top",
              width: 1.7,
              height: 1.5,
            },
          ],
        },
        {
          step: 9,
          isMain: true,
          name: "9-m",
          hasSub: true,
          cameraRot: { main: { x: 0, y: 5 }, sub: { x: -0.3, y: 4.7 } },
          clickAreasMain: [
            {
              rot: "0 14 -30",
              infoLoc: "top",
              width: 1,
              height: 1,
            },
          ],
          subAreas: [
            {
              rot: "0 0 -20",
              infoLoc: "top",
              width: 1.7,
              height: 1.7,
            },
          ],
        },
        {
          step: 10,
          isMain: true,
          name: "10-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.1, y: 4.6 } },
          clickAreasMain: [
            {
              rot: "0 -8 5",
              infoLoc: "bottom",
              width: 1,
              height: 1.2,
            },
          ],
        },
        {
          step: 11,
          isMain: true,
          name: "11-m",
          hasSub: false,
          cameraRot: { main: { x: 0.2, y: 4.3 } },
          clickAreasMain: [
            {
              rot: "0 -24 14",
              infoLoc: "bottom",
              width: 1.2,
              height: 1.2,
            },
          ],
          subAreas: [],
        },
        {
          step: 12,
          isMain: true,
          name: "12-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 5.9, y: 4.72 } },
          clickAreasMain: [
            {
              rot: "0 0 -55",
              infoLoc: "top",
              width: 1.2,
              height: 1.2,
            },
          ],
        },
        {
          step: 13,
          isMain: true,
          name: "13-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 6.4, y: 4.75 } },
          clickAreasMain: [
            {
              rot: "0 27 -5",
              infoLoc: "top",
              width: 0.8,
              height: 1.2,
            },
            {
              rot: "0 -23 10",
              infoLoc: "top",
              width: 0.6,
              height: 1.2,
            },
          ],
        },
        {
          step: 14,
          isMain: true,
          name: "14-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 6.3, y: 4.6 } },
          clickAreasMain: [
            {
              rot: "0 -2 15",
              infoLoc: "bottom",
              questionRot: "",

              width: 0.8,
              height: 1.15,
            },
          ],
        },
        {
          step: 15,
          isMain: true,
          name: "15-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.3, y: 1 } },
          clickAreasMain: [
            {
              rot: "0 176 15",
              infoLoc: "top",
              questionRot: "0 0 0",
              width: 0.8,
              height: 0.8,
            },
            {
              rot: "0 113 3",
              infoLoc: "top",
              questionRot: "",
              width: 0.6,
              height: 0.8,
            },
          ],
        },
        {
          step: 16,
          isMain: true,
          name: "16-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0.3, y: 1.6 } },
          clickAreasMain: [
            {
              rot: "0 180 27",
              infoLoc: "bottom",
              width: 0.7,
              height: 0.75,
            },
          ],
        },
        {
          step: 17,
          isMain: true,
          name: "17-m",
          hasSub: false,
          subAreas: [],
          cameraRot: { main: { x: 0, y: 1.4 } },
          clickAreasMain: [
            {
              rot: "0 170 -5",
              infoLoc: "top",
              width: 3,
              height: 1.5,
            },
          ],
        },
      ],
      infoList: [
        {
          main: [
            [
              "מערכת מי עיבוי קונדנסר בעבודה.",
              "• משאבה עובדת.",
              "• וסתי גובה באוטומט.",
            ],
          ],
          sub: ["יש לבדוק שהלחץ תקין."],
        },
        {
          main: [
            [
              "במידת הצורך נמלא מים במעבה.",
              "מומלץ להשאיר ברז מילוי פתוח עד ההפעלה, לצורך סחרור.",
              "לחץ על הברז לפרטים.",
            ],
          ],
          sub: ["ברז מילוי למעבה."],
        },
        {
          main: [
            [
              "מלכודת 8.5 בעבודה מלאה.",
              "• יש לבדוק שהברזים פתוחים.",
              "לחץ כדי להתקרב.",
            ],
          ],
          sub: ["בדיקה: ברז ריקון לפלאש מתפקד."],
        },
        {
          main: [
            [
              "מעבה קיטור אטימה.",
              "לפני יצירת ואקום במעבה הגדול יש להכניס את מעבה קיטור האטימה לעבודה.",
            ],
          ],
        },
        {
          main: [
            ["קו קיטור 8.5:", "יש לוודא ניקוז מים מקו הקיטור."],
            ["קו קיטור 8.5:", "יש לוודא ניקוז מים מקו הקיטור."],
          ],
        },
        {
          main: [["פתיחת קו 8.5."]],
        },
        {
          main: [
            [
              "קו זה מזין את וסת  401PV – ווסת קיטור אטימה. ",
              "יש לוודא שהווסת סגור במצב ידני. (mark 6)",
            ],
          ],
          sub: [""],
        },
        {
          main: [
            [
              "קו זה מזין קיטור ליצירת ואקום במעבה קיטור אטימה.",
              "לחץ כדי לראות את שעון הואקום.",
            ],
          ],
          sub: ["שעון וואקום."],
        },
        {
          main: [
            [
              "בקו ה-VENT של מעבה קיטור אטימה קיים ברז ניקוז. (נמצא בברך, מתחת לקונדנסר)",
              "הניקוז חייב להיות פתוח כדי לנקז מים ולהימנע מבלימה בקו.",
              "כאשר מתחיל לצאת קיטור, רצוי לסגור מעט את הברז.",
              "לחצו על הניקוז כדי לראות מקרוב.",
            ],
          ],
          sub: ["ניקוז ברך ב-VENT."],
        },
        {
          main: [
            [
              "נתחיל בהזרמת הקיטור עד לווסת הקיטור למעבה קיטור אטימה.",
              "נוודא שברז ניקוז 1-DB .מתחת לבית הטורבינה פתוח",
              "(הניקוז השמאלי מבין השלושה)",
            ],
          ],
        },

        {
          main: [["יש למלא מים בברך הברומטרית"]],
          sub: ["יש למלא מים בברך הברומטרית"],
        },

        {
          main: [
            [
              "לאחר מילוי מים בברך הברומטרית, יש לפתוח את ווסת הקיטור למעבה באופן ידני",
            ],
          ],
        },
        {
          main: [
            ["בתחילת הפעלת הטורבינה נעבוד עם הווסת הידני עד להתייצבות המערכת"],
            ["שעוני ואקום מעבה קיטור אטימה", "הערך לשמירה: 480-"],
          ],
        },
        {
          main: [
            [
              "יש לוודא שהניקוז אחרי ווסת השחרור פתוח",
              "(נמצא מתחת לבית הטורבינה, ליד קו הקזה 1.8).",
              "ניקוז זה חייב להישאר פתוח כל הזמן.",
            ],
          ],
        },
        {
          main: [
            ["ווסת שחרור"],
            [
              "בשלב זה נתחיל בפתיחת וסת קיטור האטימה 401-PV באופן איטי עד להגעה ל-  SP=0.16",
              "כאשר הטורבינה מועמסת נשמור על ערך של:",
              "0.12-0.14",
            ],
          ],
        },
        {
          main: [["לבדוק:", "• הנשם נקי.", "• לא יוצאים אדים."]],
        },
        {
          main: [
            [
              "לאחר שבצענו את הבדיקות והכל תקין...",
              "אפשר ליצור ואקום במעבה הגדול.",
            ],
          ],
        },
      ],
      frameDescribe: [
        { frame: 1, title: "מי עיבוי" },
        { frame: 2, title: null },
        { frame: 3, title: null },
        { frame: 4, title: "מעבה קיטור אטימה" },
        { frame: 5, title: "קיטור 8.5" },
        { frame: 6, title: "קיטור 8.5" },
        { frame: 7, title: "ווסת קיטור אטימה pv401" },
        { frame: 8, title: "קיטור לטובת ואקום" },
        { frame: 9, title: "וונט מעבה קיטור אטימה" },
        { frame: 10, title: "ניקוז db1" },
        { frame: 11, title: "ברך ברומטרית" },
        { frame: 12, title: "ווסת קיטור למעבה" },
        { frame: 13, title: "משדר לחץ(ואקום)" },
        { frame: 14, title: "ניקוז למעבה dz1" },
        { frame: 15, title: "ווסת שחרור pv400" },
        { frame: 16, title: null },
        { frame: 17, title: null },
      ],
    };
  },
  methods: {
    toggleScreen: function () {
      let elem = document.body;
      // ## The below if statement seems to work better ## if ((document.fullScreenElement && document.fullScreenElement !== null) || (document.msfullscreenElement && document.msfullscreenElement !== null) || (!document.mozFullScreen && !document.webkitIsFullScreen)) {
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        if (elem.requestFullScreen) {
          elem.requestFullScreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullScreen) {
          elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.isFullScreen = !this.isFullScreen;
      }
    },
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
* {
  box-sizing: border-box;
}
#app {
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
  // width: 100%;
  // height: 100%;
}

.loading-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #d5adc8;
  background-image: linear-gradient(315deg, #d5adc8 0%, #ff8489 74%);
  top: 0;
  z-index: 3;
}
.lds-dual-ring {
  display: inline-block;
  width: 140px;
  height: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .txt {
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 94px;
  height: 94px;
  margin: 8px;
  border-radius: 50%;
  border: 15px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.gradient {
  background: #00b4db; /* fallback for old browsers */
  background: linear-gradient(
    to right,
    #0083b0,
    #00b4db
  ); /* Chrome 10-25, Safari 5.1-6 */
}
</style>
