<template>
  <a-entity class="tripod-blur">
    <a-circle
      rotation="-90 0 0"
      position="0 -1.6 0"
      radius="1.1"
      src="#blur-canvas"
      material="alphaTest: 0.2; transparent: true;"
    >
      <a-image
        scale="1 1 1"
        position="0 0.04 0.2"
        src="img/icl_logo.webp"
        material="alphaTest: 0.4; transparent: true;"
      ></a-image>
    </a-circle>
  </a-entity>
</template>

<script>
export default {
  name: "TripodBlur",

  props: ["imgList", "infoList"],
  data: () => {
    return {};
  },
  mounted() {
    const canvas = document.getElementById("blur-canvas");
    const ctx = canvas.getContext("2d");
    let w = canvas.width;
    let h = canvas.height;
    ctx.globalAlpha = 0.5;
    ctx.fillStyle = "gray";
    ctx.filter = "blur(" + 30 + "px)";
    ctx.fillRect(0, 0, w, h);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
