import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
// import "aframe";
import "aframe-look-at-component";

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  "a-entity",
  "a-sky",
  "a-image",
  "a-scene",
  "a-assets",
  "a-circle",
];

new Vue({
  render: (h) => h(App),
}).$mount("#app");
