<template>
  <div class="instructions">
    <p class="text-header" dir="auto">ברוכ/ה הבא/ה לסיור וירטואלי - לומדת קיטור</p>
    <span class="sub-text" dir="auto">
      <span class="flex-wrap"
        ><img src="img/icons/vrpano.svg" class="icon" alt="" />
        <p>הסיור מורכב מתמונות 360 ברחבי המפעל.</p></span
      >
      <span class="flex-wrap"
        ><img :src="`img/icons/${!isMobile ? 'mouse' : 'touch'}.svg`" alt="" class="icon" />
        <p v-if="!isMobile">
          גרירה של העכבר תוך כדי לחיצה תזיז את המבט כך שתוכל/י לראות את החלל ב360
          מעלות.
        </p>
        <p v-else>
          גרירת האצבע על המסך תזיז את המבט כך שתוכל/י לראות את החלל ב360 מעלות.
        </p>
        </span
      >
      <span class="flex-wrap"
        ><div class="icon yellow-box box"></div>
        <p>
          לחיצה על הריבועים הצהובים יעבירו אתכם לתמונת תקריב של אזור העניין.
        </p></span
      >
      <span class="flex-wrap"
        ><div class="icon red-box box"></div>
        <p>ריבועים אדומים אינם לחיצים אך מסמנים את אזור העניין.</p></span
      >
      <span class="flex-wrap"
        ><img src="img/icons/arrow-full.svg" alt="" class="icon circle" />
        <p>
          מעבר בין התמונות יתבצע על ידי לחיצה על חצי המקלדת או על כפתורי 'הבא'
          ו'הקודם' בצידי המסך.
        </p></span
      >
      <span class="flex-wrap"
        ><img src="img/icons/map-full.svg" alt="" class="icon circle" />
        <p>
          ניתן גם לעבור על ידי לחיצה כל כפתור המפה בחלקו העליון של המסך ולחיצה
          על האזור הרצוי, המפה גם תשמש אותך כשתרצה להבין את מיקומך במפעל, המיקום
          במפה מתעדכן לפי התמונה בה אתה צפית בזמן שלחצת על כפתור המפה.
        </p></span
      >
      <span class="flex-wrap"
        ><img src="img/icons/red-info.svg" alt="" class="icon circle" />
        <p>
          בסיור הוירטואלי ניתן גם למצוא את כפתורי סימן השאלה, לחיצה תציג את
          הפעולה הנדרשת לביצוע באזור העניין.
        </p></span
      >
    </span>

  </div>
</template>

<script>
// require("aframe");

export default {
  name: "Instructions",
  props: ["isMobile"],
  data: () => {
    return {};
  },

  mounted() {},
  methods: {
    resize: function () {
      console.log("sizin");
    },
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.instructions {
  overflow-y: auto;
}
.text-header {
  text-align: center;
  font-size: 2.3rem;
  margin: 0;
  margin-bottom: 5px;
  @media only screen and (max-width: 900px) {
    font-size: 1.5rem;
  }
}
.flex-wrap {
  display: flex;
  p {
    font-size: 1.3rem;
  }
  .icon {
    width: 50px;
    margin-left: 20px;
  }
  .circle {
    width: 48px;
    height: 48px;
    transform: translateY(20%);
  }
  .box {
    border-radius: 10px;
    width: 40px;
    height: 40px;
    transform: translateY(50%) translateX(-3px);
    margin-left: 20px;
  }
  .yellow-box {
    border: 6px solid yellow;
  }
  .red-box {
    border: 6px solid red;
  }
}
.instructions {
  @media only screen and (max-width: 900px) {
    max-height: 80vh;
    .sub-text {
      @media only screen and (max-height: 302px) {
        max-height: 50%;
      }
      max-height: 60%;
      display: block;
      overflow-y: scroll;
    }
  }
}
</style>
